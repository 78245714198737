import React from "react";
import Button from "@material-ui/core/Button";
import { showNotification } from "react-admin";
import * as S3 from "aws-sdk/clients/s3";

import { connect } from "react-redux";
import { change } from "redux-form";
import { REDUX_FORM_NAME } from "react-admin";

const s3_region_uploadFolder = process.env.REACT_APP_s3_region_uploadFolder;
const s3_accessKeyId = process.env.REACT_APP_s3_accessKeyId;
const s3_accessKeySecret = process.env.REACT_APP_s3_accessKeySecret;
const s3_region = process.env.REACT_APP_s3_region;
const s3_region_bucket = process.env.REACT_APP_s3_region_bucket;

const getFileBuffer = async (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = function () {
      try {
        resolve(reader.result);
      } catch (error) {
        resolve(error);
      }
    };
  });
};

const LogoInput = ({ formData, dispatch }) => {
  //   handleClick = () => {
  //     const { record } = this.props;

  //     const bucket = new S3({
  //       accessKeyId: process.env.REACT_APP_s3_accessKeyId,
  //       secretAccessKey: process.env.REACT_APP_s3_accessKeySecret,
  //       region: process.env.REACT_APP_s3_region,
  //     });
  //     const params = {
  //       Bucket: process.env.REACT_APP_s3_region_bucket,
  //     };

  //     if (fileType === "flyer") {
  //       params["Key"] =
  //         process.env.REACT_APP_s3_region_uploadFolder +
  //         `${record.id}_${fileType}.pdf`;
  //       bucket.getObject(params, (err, data) => {
  //         if (err) return showNotification(`${fileType} not found !`);
  //         let file = new Blob([data.Body.buffer]);
  //         download(file, `${record.name}_${fileType}.pdf`, data.ContentType);
  //       });
  //     } else {
  //       let objectParams = Object.assign({}, params);
  //       params["Prefix"] =
  //         process.env.REACT_APP_s3_region_uploadFolder +
  //         `${record.id}_${fileType}`;

  //       bucket.listObjects(params, (err, res) => {
  //         if (err) return showNotification(`${fileType} not found !`);
  //         if (res.Contents.length > 0) {
  //           objectParams["Key"] = res.Contents[0].Key;
  //           bucket.getObject(objectParams, (err, data) => {
  //             if (err) return console.error(err);
  //             const ext = objectParams["Key"].split(".")[1];
  //             let file = new Blob([data.Body.buffer]);
  //             download(
  //               file,
  //               `${record.name}_${fileType}.${ext}`,
  //               data.ContentType
  //             );
  //           });
  //         } else {
  //           showNotification(`${fileType} not found !`);
  //           return;
  //         }
  //       });
  //     }
  //   };

  const { id: userId, logoKey } = formData;

  const onFileInputChange = async (e) => {
    const [file] = e.target.files;
    const fileBuffer = await getFileBuffer(file);

    const bucket = new S3({
      accessKeyId: s3_accessKeyId,
      secretAccessKey: s3_accessKeySecret,
      region: s3_region,
    });
    const key = `${s3_region_uploadFolder}${userId}_${file.name}`;
    const params = {
      Bucket: s3_region_bucket,
      Key: key,
      Body: fileBuffer,
      ContentType: file.type
    };

    await bucket.putObject(params).promise();

    dispatch(change(REDUX_FORM_NAME, "logoKey", key));
  };

  return (
    <>
      <Button variant="outlined" color="primary" component="label">
        Upload Logo
        <input
          type="file"
          hidden
          accept="image/*"
          onChange={onFileInputChange}
        />
      </Button>
      {logoKey && <span> {logoKey}</span>}
      <br />
    </>
  );
};

export default connect(null, {
  showNotification,
})(LogoInput);
