import React, { Fragment } from 'react';
import { Create, Edit, List, ArrayInput, SimpleFormIterator, Datagrid, TextField, SimpleForm, DisabledInput, TextInput, SelectInput, BooleanInput, ReferenceInput, FormDataConsumer, Filter, ReferenceArrayInput, SelectArrayInput, ReferenceManyField, SingleFieldList, ChipField, ReferenceField, required } from 'react-admin';
import GeneratePasswordInput from '../components/generatePasswordInput';
import IrisInput from '../components/IrisInput';
import DepsInput from '../components/DepsInput';
import LogoInput from '../components/LogoInput';

const userTypeChoices = [
    { id: "Headoffice" },
    { id: 'Affiliate' },
    { id: 'Independant' }
]


const UserTitle = ({ record }) => {
    return <span> {record.username ? `"User ${record.username}"` : 'Create User'}</span>;
};


const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="username.$regex" alwaysOn />
    </Filter>
);


export const UserList = props => (
    <List {...props} filters={<UserFilter />}>
        <Datagrid rowClick="edit">
            <TextField source="username" />
            <TextField source="type" />
            <ReferenceField label="Headoffice" source="headOffice" reference="users" allowEmpty>
                <TextField source='username' />
            </ReferenceField>
            <TextField source="email" type="email" />
            <TextField source="company" />
            <TextField source="phone" />
            <ReferenceManyField label="Countries" reference="countries" target="users">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceManyField>

        </Datagrid>
    </List>
);


//Need to clean headOffice after changing Type of user;

export const UserEdit = props => (
    <Edit title={<UserTitle />} {...props}>
      <UserForm    />
    </Edit>
);


export const UserCreate = props => (
    <Create title={<UserTitle />} {...props}>
        <UserForm  showusername='true' />
    </Create>
);

const headofficeName = choice => `${choice.company} - ${choice.username}`;

const validateUserCreation = values => {
    const errors = {};
    if(values.type === "Affiliate" && values.headOffice === values.id) {
        errors.headOffice = ["You cannot set yourself as your headoffice."]
    }
    return errors;
}

const UserForm = props => (
    <SimpleForm {...props} validate={validateUserCreation}>
        <BooleanInput source='enabled' defaultValue={true} />
        { 
            props.showusername ? 
                <TextInput source="username" validate={required()}/> 
                :
                <DisabledInput source="username" />

        
        }
        <GeneratePasswordInput source="password" validate={props.showusername} />
        <TextInput source="email" type='email' validate={required()} />
        <SelectInput source='type' choices={userTypeChoices} optionText="id" optionValue='id' validate={required()} />
        <FormDataConsumer>
            {({ formData, ...rest }) => formData.type === userTypeChoices[1].id &&
                <ReferenceInput label="Headoffice" source="headOffice" reference="users" filter={{ type: userTypeChoices[0].id }} fullWidth validate={required()}
                    {...rest}>
                    <SelectInput optionText={headofficeName} />
                </ReferenceInput>
            }

        </FormDataConsumer>

        <TextInput source="company" validate={required()} />
        <TextInput source="companyAddress" validate={required()} />
        <TextInput source="siret" label="SIRET" validate={required()} />
        <TextInput source="phone" validate={required()} />
        <FormDataConsumer>
            {
                ({ formData, ...rest }) => formData.type !== userTypeChoices[1].id &&
                    <Fragment>
                        <ReferenceArrayInput label="Countries" source="countries" reference="countries" validate={[required()]}  {...rest}>
                            <SelectArrayInput optionText="name" optionValue="id" />
                        </ReferenceArrayInput> <br />

                        <ReferenceInput label="Prospect pricing" source="pricing" reference="pricing" validate={required()}  {...rest}>
                            <SelectInput optionText="name" optionValue="id" />
                        </ReferenceInput>
                        <br/>

                        <ReferenceInput label="Buy pricing" source="buyPricing" reference="pricing"  {...rest}>
                            <SelectInput optionText="name" optionValue="id" />
                        </ReferenceInput>
                        <br/>

                        <ReferenceInput label="ELM Pricing" source="elmPricing" reference="elmPricing" {...rest}>
                            <SelectInput optionText="name" optionValue="id" />
                        </ReferenceInput>
                        <br />
                        <TextInput source="routerEmail" type='email' {...rest} validate={required()} /> <br />
                        <TextInput source="billingEmail" type='email' {...rest} validate={required()} />
                        <BooleanInput source='makeReduction' {...rest} />
                        <BooleanInput source='flyerStep' {...rest} />
                        { formData.flyerStep && (
                            <ArrayInput source="customPostages" label="Custom postage pricings">
                                <SimpleFormIterator>
                                    <TextInput source="name" label='Name' validate={required()}/>
                                    <ArrayInput source="prices" validate={required()}>
                                        <SimpleFormIterator>
                                            <TextInput source="range" validate={required()}/>
                                            <TextInput source="price" validate={required()}/>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </SimpleFormIterator>
                            </ArrayInput>
                        )}
                        <BooleanInput source='subscription' {...rest} />
                    </Fragment>
            }
        </FormDataConsumer>
        
        <BooleanInput source='makePayment' label='Can make payments' />
        <BooleanInput source='accessDataVis' label='Can access to Geo Strategy' options={{value: 'true'}} />
        <BooleanInput source='accessElm' label='Can access to ELM generation' options={{value: 'true'}} />
        <BooleanInput source='buyAddresses' label='Can buy addresses' options={{value: 'true'}} />
        <BooleanInput source='showStores' label='Can show piscinists' options={{value: 'true'}} />
        <FormDataConsumer>
            {formDataProps => (
                <>
                    <LogoInput {...formDataProps} />
                    <DepsInput {...formDataProps} />
                    <IrisInput {...formDataProps} />
                </>
            )}
        </FormDataConsumer>

    </SimpleForm>
)
