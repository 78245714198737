import React from 'react';
import { Create, Edit, List, Datagrid, TextField, SimpleForm, DisabledInput, TextInput, required } from 'react-admin';
import GeneratePasswordInput from '../components/generatePasswordInput';


const AdminTitle = ({ record }) => {
    return <span>Admin {record ? `"${record.username}"` : ''}</span>;
};


export const AdminList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="username" />
        </Datagrid>
    </List>
);


export const AdminEdit = props => (
    <Edit  title={<AdminTitle />} {...props}>
        <SimpleForm>
           <DisabledInput source="username"  />
           <GeneratePasswordInput source="password" validate={false} />
        </SimpleForm>
    </Edit>
);


export const AdminCreate = props => (
    <Create title={<AdminTitle />} {...props}>
        <SimpleForm>
            <TextInput source="username" validate={required()} />
            <GeneratePasswordInput source="password" validate={true} />
        </SimpleForm>
    </Create>
);