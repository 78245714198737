import React from 'react';
import {  Edit, List, Datagrid, TextField, SimpleForm, TextInput, BooleanField, BooleanInput, Filter } from 'react-admin';

const PoolsListForm = props => (
    <SimpleForm {...props} >
        <BooleanInput source="npai" />
        <BooleanInput source="red_list"  />
    </SimpleForm>
)

const Filters = props => (
    <Filter {...props}>
        <TextInput label="Numero" source="numero" alwaysOn />
        <TextInput label="REPETEUR" source="rep" alwaysOn />
        <TextInput label="nom voie" source="nom_voie.$regex" alwaysOn />
        <TextInput label="Commune" source="nom_com.$regex" alwaysOn />
        <TextInput label="Code postal" source="code_post" alwaysOn />
    </Filter>
)

export const PoolsListList = props => (
    <List {...props} filters={<Filters />}>
        <Datagrid rowClick="edit">
            <TextField source="numero" />
            <TextField source="rep" />
            <TextField source="nom_voie" />
            <TextField source="nom_com" />
            <TextField source="code_post" />
            <BooleanField source='npai'/>
            <BooleanField source='red_list' />
        </Datagrid>
    </List>
)



export const PoolsListEdit = props => (
    <Edit {...props}>
        <PoolsListForm />
    </Edit>
)

