import React from "react";
import {
  Create,
  Edit,
  List,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  NumberField,
  NumberInput,
  required,
} from "react-admin";

const ElmPricingForm = (props) => (
  <SimpleForm {...props}>
    <TextInput source="name" validate={required()} />
    <NumberInput source="price_dep" label="Price per dep" min={0} step={0.01} />
    <NumberInput source="price_iris" label="Price per iris" min={0} step={0.01} />
    <NumberInput source="price_zipcode" label="Price per zip code" min={0} step={0.01} />
  </SimpleForm>
);

export const ElmPricingList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <NumberField source="price_dep" label="Price per dep" />
      <NumberField source="price_iris" label="Price per iris" />
      <NumberField source="price_zipcode" label="Price per zip code" />
    </Datagrid>
  </List>
);

export const ElmPricingCreate = (props) => (
  <Create {...props}>
    <ElmPricingForm />
  </Create>
);

export const ElmPricingEdit = (props) => (
  <Edit {...props}>
    <ElmPricingForm />
  </Edit>
);
