import React, { Component } from 'react';
import PropTypes from 'prop-types';
import download from 'downloadjs';
import Button from '@material-ui/core/Button';
import { showNotification } from 'react-admin';
import { connect } from 'react-redux';
import * as S3 from 'aws-sdk/clients/s3';


class DownloadFileButton extends Component {
    

    handleClick = () => {
        const {  record, showNotification, fileType } = this.props;
        showNotification(`Downloading ${fileType}`)
        
        const bucket = new S3({
            accessKeyId: process.env.REACT_APP_s3_accessKeyId,
            secretAccessKey: process.env.REACT_APP_s3_accessKeySecret,
            region: process.env.REACT_APP_s3_region
        });
        const params = {
            Bucket: process.env.REACT_APP_s3_region_bucket,
        }
        
        if(fileType === 'flyer'){
            params['Key']= process.env.REACT_APP_s3_region_uploadFolder+`${record.id}_${fileType}.pdf`;
            bucket.getObject(params, (err, data) => {
                if(err) return showNotification(`${fileType} not found !`)
                let file = new Blob([data.Body.buffer]);
                download(file, `${record.name}_${fileType}.pdf`, data.ContentType);
            })
        }
        else {
            let objectParams = Object.assign({}, params);
            params['Prefix'] = process.env.REACT_APP_s3_region_uploadFolder+`${record.id}_${fileType}`
        

            bucket.listObjects(params, (err, res) => {
                if(err) return showNotification(`${fileType} not found !`)
                if(res.Contents.length > 0){
                    objectParams['Key'] = res.Contents[0].Key;
                    bucket.getObject(objectParams, (err, data) => {
                        if(err) return console.error(err);
                        const ext = objectParams['Key'].split('.')[1];
                        let file = new Blob([data.Body.buffer]);
                        download(file, `${record.name}_${fileType}.${ext}`, data.ContentType);
                    })
                }
                else {
                    showNotification(`${fileType} not found !`)
                    return;
                }
            })
        }

        
    }

    render() {
        const { record } = this.props;

        if(!record.user) return "";
        
        if(record.user.flyerStep || (record.user.type === 'Affiliate' && record.user.headOffice.flyerStep))
            return <Button variant='outlined' color='primary' onClick={this.handleClick} >{this.props.fileType}</Button>
        return '';
    }
}




DownloadFileButton.propTypes = {
    record: PropTypes.object,
    showNotification: PropTypes.func,
    fileType: PropTypes.string.isRequired
};

export default connect(null, {
    showNotification
}
)(DownloadFileButton);