import React from 'react';
import { Create, Edit, List, Datagrid, TextField, SimpleForm, TextInput, SimpleFormIterator,ArrayInput, required, BooleanInput, BooleanField } from 'react-admin';

const PostageForm = props => (
    <SimpleForm {...props} >
        <TextInput source="name" label='Name' validate={required()}/>
        <BooleanInput source="default" label={'Prix de base'} />
        <ArrayInput source="prices" validate={required()}>
            <SimpleFormIterator>
                    <TextInput source="range" validate={required()}/>
                    <TextInput source="price" validate={required()}/>
            </SimpleFormIterator>
        </ArrayInput>
    </SimpleForm>
)


export const PostageList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <BooleanField source="default" />
        </Datagrid>
    </List>
)

export const PostageCreate = props => (
    <Create {...props}>
        <PostageForm />
    </Create>
)

export const PostageEdit = props => (
    <Edit {...props}>
        <PostageForm />
    </Edit>
)

