import React, { Component } from 'react';
import PropTypes from 'prop-types';
import download from 'downloadjs';
import Button from '@material-ui/core/Button';
import { showNotification } from 'react-admin';
import { connect } from 'react-redux';


const API_URL = process.env.REACT_APP_API_URL;



class DownloadXlsButton extends Component {

    handleClick = () => {
        const {  record, showNotification } = this.props;
        showNotification(`Downloading ${record.name}.xls`);
        fetch(`${API_URL}/campaigns/${record.id}/addresses`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'x-api-key': process.env.REACT_APP_API_KEY,
                Accept: "application/vnd.ms-excel"
            }})
        .then(response => {
            if(response.status === 200){
                return response.text().then(atob);
            }
            else {
                showNotification(`Failed to download ${record.name}.xls`);
                return;
            }
        })
        .then(body => {
            download(body, `${record.name}.xls`, "application/octet-stream");
        })
        .catch(err => console.error(`Error while downloading ${record.name}.xls`))
    }
    render() {
        return <Button variant='outlined' color='primary' onClick={this.handleClick} >Xls</Button>
    }

}



DownloadXlsButton.propTypes = {
    record: PropTypes.object,
    showNotification: PropTypes.func,
};




export default connect(null, {
    showNotification,
})(DownloadXlsButton);