import React from 'react';
import { Create, Edit, List, Datagrid, TextField, SimpleForm, TextInput, required, NumberInput } from 'react-admin';

const VerificationForm = props => (
    <SimpleForm {...props} >
        <NumberInput source="numero" label='Numero' validate={required()}/>
        <TextInput source="rep" label='REPETEUR' />
        <TextInput source="nom_voie" label='nom voie' validate={required()}/>
        <TextInput source="nom_com" label='Commune' validate={required()}/>
        <TextInput source="code_post" label='Code postal' validate={required()}/>
    </SimpleForm>
)


export const VerificationList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="numero" />
            <TextField source="rep" />
            <TextField source="nom_voie" />
            <TextField source="nom_com" />
            <TextField source="code_post" />
        </Datagrid>
    </List>
)

export const VerificationCreate = props => (
    <Create {...props}>
        <VerificationForm />
    </Create>
)

export const VerificationEdit = props => (
    <Edit {...props}>
        <VerificationForm />
    </Edit>
)

