import React from 'react';    
import { Field } from 'redux-form';
import { generate } from 'generate-password';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { REDUX_FORM_NAME, required }  from 'react-admin';
import { change } from 'redux-form';



class PasswordInput extends React.Component{
    handleChange(source) {
        this.props.meta.dispatch(change(REDUX_FORM_NAME, source, generate({length: 10, numbers: true})))
    }
    render(){
        let { input, dispatch, label, meta: { touched, error },source,  ...custom } = this.props;
        return (
            
            <span>
                <TextField label={label} 
                    error={!!(touched && error)}
                    helperText={touched && error}
                    {...custom}  
                    {...this.props.input}
                />
                
                <Button 
                    onClick={() => { this.handleChange(source) }} color="primary" >Generate</Button>
            </span> 
            
        )
    }
}


const GeneratePasswordInput = ({validate, source }) => (
    <Field name={source} component={PasswordInput} type="text" label={source} validate={validate ? required() : null} source={source} required={validate ? true : null} />
)
export default GeneratePasswordInput;