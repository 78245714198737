import React from "react";
import {
  Edit,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  SelectInput,
  SimpleForm,
  TextInput,
  Filter,
  required,
} from "react-admin";

const ElmListForm = (props) => (
  <SimpleForm {...props}>
    <SelectInput
      source="status"
      optionText="id"
      optionValue="id"
      choices={[{ id: "ORDERED" }, { id: "PAYED" }, { id: "WAITING_FOR_HO" }, { id: "DENIED" }]}
      validate={required()}
    />
    <TextInput label="File key" source="fileKey" />
  </SimpleForm>
);

const Filters = (props) => (
  <Filter {...props}>
    <TextInput label="Status" source="status" alwaysOn />
    <TextInput label="File key" source="fileKey" alwaysOn />
  </Filter>
);

const StringArrayField = ({ record, source }) =>
  record[source] ? record[source].join(", ") : null;

export const ElmList = (props) => (
  <List {...props} filters={<Filters />}>
    <Datagrid rowClick="edit">
      <TextField source="status" />
      <ReferenceField label="User" source="user" reference="users">
        <TextField source="username" label="User" />
      </ReferenceField>
      <TextField source="dep" />
      <StringArrayField source="irises" />
      <StringArrayField source="zipCodes" />
      <TextField source="fileKey" />
      <TextField source="price.value" />
      <TextField source="date" />
    </Datagrid>
  </List>
);

export const ElmEdit = (props) => (
  <Edit {...props}>
    <ElmListForm />
  </Edit>
);
