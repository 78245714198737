import React from 'react';
import { Create, Edit, List, Datagrid, TextField, SimpleForm, TextInput, ReferenceArrayInput, SelectArrayInput, ReferenceManyField, SingleFieldList, ChipField, required } from 'react-admin';

const CountryTitle = ({ record }) => {
    return <span>Country {record ? `"${record.name}"` : ''}</span>;
};

export const CountryList = props => (
    <List {...props}>
        <Datagrid rowClick='edit'>
            <TextField source='name' />
            <ReferenceManyField label='Users' reference="users" target='countries'>
                <SingleFieldList>
                    <ChipField source="username" />
                </SingleFieldList>
            </ReferenceManyField>
        </Datagrid>
    </List>
);

export const CountryCreate = props => (
    <Create  title={<CountryTitle />} {...props}>
        <CountryForm />
    </Create>
)


export const CountryEdit = props => (
    <Edit title={<CountryTitle />} {...props}>
        <CountryForm  />
    </Edit>
)

const CountryForm = props => (
    <SimpleForm {...props}>
        <TextInput source='name' validate={required()} />
        <TextInput source='tva' validate={required()} />
        <ReferenceArrayInput label="User" source="users" reference="users">
            <SelectArrayInput optionText="username" optionValue="id"  />
        </ReferenceArrayInput>
    </SimpleForm>
)