import React, { Fragment, useState } from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ClearIcon from "@material-ui/icons/Clear";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { connect } from "react-redux";
import { change } from "redux-form";
import { REDUX_FORM_NAME } from "react-admin";

const DepsInput = ({ formData, dispatch }) => {
  const [depInput, setDepInput] = useState("");

  const depsToEnable = formData.depsToEnable || [];

  const removeDepartment = (department) => {
    const newDepsToEnable = depsToEnable.filter(
      ({ dep }) => dep !== department
    );
    dispatch(change(REDUX_FORM_NAME, "depsToEnable", newDepsToEnable));
  };

  const onDepAdd = () => {
    if (!depInput) return;
    setDepInput("");
    if (depsToEnable.find(({ dep }) => dep === depInput)) return;
    dispatch(
      change(REDUX_FORM_NAME, "depsToEnable", [
        ...depsToEnable,
        { dep: depInput },
      ])
    );
  };

  return (
    <Fragment>
      <List component="div" disablePadding>
        {depsToEnable.map(({ dep }) => (
          <ListItem key={dep}>
            <ListItemText inset primary={dep} />
            <IconButton onClick={() => removeDepartment(dep)}>
              <ClearIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <TextField
        name="dep"
        onChange={(e) => setDepInput(e.target.value.trim())}
        value={depInput}
        placeholder="department"
      />
      <Button onClick={onDepAdd} disabled={!depInput}>
        <AddCircleOutlineIcon /> Add
      </Button>
    </Fragment>
  );
};

export default connect()(DepsInput);
