import React, { Fragment, useState } from 'react';
import { compose } from "redux";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PublishIcon from '@material-ui/icons/Publish';
import Collapse from '@material-ui/core/Collapse';
import TextField from '@material-ui/core/TextField';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import { parse as papaparse } from 'papaparse';
import { change } from 'redux-form'
import { REDUX_FORM_NAME } from 'react-admin'

const parseCsv = file => new Promise((resolve, reject) => {
    papaparse(file, {
        delimiter: ';',
        complete: resolve,
        error: reject
    });
});

const irisToDepartment = iris => {
    const first2chars = iris.substr(0, 2);
    if(first2chars === "97") return iris.substr(0, 3);
    return first2chars;
}

const departmentSort = (depA, depB) => {
    if (depA.attr < depB.attr) return -1;
    if ( depA.attr > depB.attr) return 1;
    return 0;
}

const getIrisesFromFile = async file => {
    const { data } = await parseCsv(file);
    const keys = data[0];
    const primaryKey = keys[0];

    const values = data.map(row => (
        keys.reduce((acc, key, index) => ({
            ...acc,
            [key]: row[index]
        }), {})
    ));
    return values.map(v => ({iris: v[primaryKey]}));
}

const groupIrisByDepartment = irisToEnable => {
    const departmentMap = {};
    for(let iris of irisToEnable){
        const department = irisToDepartment(iris.iris);
        if(!departmentMap[department]) {
            departmentMap[department] = [];
        }
        departmentMap[department].push(iris);
    }
    return Object.keys(departmentMap).sort(departmentSort).map(department => ({
        irises: departmentMap[department].map(_ => _.iris),
        department
    }));
}

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing.unit * 2,
    },
    csvInput: {
        display: "none"
    }
});

const IrisInput = ({ formData, dispatch, classes }) => {
    const [iris, setIris] = useState("");
    const [depOpenMap, setDepOpenMap] = useState({});

    const toggleDepOpen = department => {
        setDepOpenMap({
            ...depOpenMap,
            [department]: !depOpenMap[department]
        })
    }

    const removeDepartment = department => {
        const irisToEnable = formData.irisToEnable || [];
        const newIrisToEnable = irisToEnable.filter(({ iris }) =>
            irisToDepartment(iris) !== department
        )
        dispatch(change(REDUX_FORM_NAME, 'irisToEnable', newIrisToEnable));
    }

    const removeIris = irisToRemove => {
        const irisToEnable = formData.irisToEnable || [];
        const newIrisToEnable = irisToEnable.filter(({ iris }) =>
            iris !== irisToRemove
        )
        dispatch(change(REDUX_FORM_NAME, 'irisToEnable', newIrisToEnable));
    }

    const onIrisAdd = () => {
        if(!iris) return;
        setIris("");
        const irisToEnable = formData.irisToEnable || [];
        dispatch(change(REDUX_FORM_NAME, 'irisToEnable', [ ...irisToEnable, { iris } ]));
    }

    const onFileUpload = async e => {
        const file = e.target.files && e.target.files[0];
        if(!file) return;
        const irisToEnable = await getIrisesFromFile(file);
        dispatch(change(REDUX_FORM_NAME, 'irisToEnable', irisToEnable));
    };

    const irisByDepartment = groupIrisByDepartment(formData.irisToEnable || []);

    return (
        <Fragment>
            <input
                type="file"
                id="text-button-file"
                className={classes.csvInput}
                accept='.csv'
                onChange={onFileUpload}
            />
            <label htmlFor="text-button-file">
                <Button color="primary" component="span" >
                    <PublishIcon/>&nbsp;IRIS Csv
                </Button>
            </label>
            <List component="nav" className={classes.root}>
                { irisByDepartment.map(({ department, irises }) => (
                    <Fragment key={department}>
                        <ListItem button onClick={() => toggleDepOpen(department)}>
                            <ListItemText primary={department} />
                            {depOpenMap[department] ? <ExpandLess /> : <ExpandMore />}
                            <IconButton onClick={() => removeDepartment(department)}><ClearIcon /></IconButton>
                        </ListItem>
                        <Collapse in={depOpenMap[department]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {irises.map(iris => (
                                    <ListItem key={iris} className={classes.nested}>
                                        <ListItemText inset primary={iris} />
                                        <IconButton onClick={() => removeIris(iris)}><ClearIcon /></IconButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Collapse>
                    </Fragment>
                )) }
            </List>
            <TextField name="iris" onChange={e => setIris(e.target.value.trim())} value={iris} placeholder="iris"/>
            <Button onClick={onIrisAdd} disabled={!iris}><AddCircleOutlineIcon/> Add</Button>
        </Fragment>
    );
}

export default compose(
    connect(),
    withStyles(styles)
)(IrisInput);
