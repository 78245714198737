import React from 'react';
import { UserList, UserEdit, UserCreate } from './resources/users';
import { AdminList, AdminEdit, AdminCreate } from './resources/admins';
import { CountryList, CountryCreate, CountryEdit } from './resources/countries';
import { PricingList, PricingCreate, PricingEdit } from './resources/pricing';
import { ElmPricingList, ElmPricingCreate, ElmPricingEdit } from './resources/elm_pricing';
import { PostageList, PostageCreate, PostageEdit } from './resources/postage';
import { ElmList, ElmEdit } from './resources/elms';

import { VerificationList, VerificationCreate, VerificationEdit } from './resources/verification-addresses';
import { PoolsListList, PoolsListEdit } from './resources/pools-list';
import authProvider from './providers/authProvider'


import { fetchUtils, Admin, Resource, Login } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { CampaignList, CampaignShow,CampaignEdit } from './resources/campaigns';
import { AddressOrderList, AddressOrderShow,AddressOrderEdit } from './resources/address-order';

const httpClient = (url, options = {}) => {
     if(!options){
          options = {};
      }
      if(!options.user){
          options.user = {
              authenticated: true,
              token: `Bearer ${localStorage.getItem('token')}`
          }    
      }
        if (!options.headers) {
            options.headers = new Headers({ Accept: 'application/json' });
        }
    
        options.headers.set('x-api-key', process.env.REACT_APP_API_KEY);

    return fetchUtils.fetchJson(url, options);
}
const dataProvider = simpleRestProvider(process.env.REACT_APP_API_URL, httpClient);

const MyLoginPage = () => <Login backgroundImage="/background.jpg" />;



const App = () => (
     <Admin loginPage={MyLoginPage} dataProvider={dataProvider} authProvider={authProvider}>
          <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} />
          <Resource name="countries" list={CountryList} create={CountryCreate} edit={CountryEdit} />
          <Resource name="pricing" list={PricingList} create={PricingCreate} edit={PricingEdit} />
          <Resource name="elmPricing" list={ElmPricingList} create={ElmPricingCreate} edit={ElmPricingEdit} />
          <Resource name="postage" list={PostageList} create={PostageCreate} edit={PostageEdit} />
          <Resource name="campaigns" list={CampaignList} show={CampaignShow} edit={CampaignEdit} />
          <Resource name="addressOrders" list={AddressOrderList} show={AddressOrderShow} edit={AddressOrderEdit} />
          <Resource name="verification" list={VerificationList} edit={VerificationEdit} create={VerificationCreate} />
          <Resource name="pools" list={PoolsListList} edit={PoolsListEdit}  />
          <Resource name="admins" list={AdminList} edit={AdminEdit} create={AdminCreate} />
          <Resource name="elms" list={ElmList} edit={ElmEdit} />
    </Admin>
);

export default App;
    