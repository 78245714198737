import React from 'react';
import { Create, Edit, List, Datagrid, TextField, SimpleForm, TextInput, ArrayInput, SimpleFormIterator, required } from 'react-admin';


const PricingForm = props => (
    <SimpleForm {...props} >
        <TextInput source="name" validate={required()}/>

        <ArrayInput source="ranges" validate={required()}>
            <SimpleFormIterator>
                <TextInput source="range" validate={required()}/>
                <TextInput source="price" validate={required()}/>
                <TextInput source="priceHO" validate={required()}/>
            </SimpleFormIterator>
        </ArrayInput>

    </SimpleForm>
)


export const PricingList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
        <TextField source="name" />
        </Datagrid>
    </List>
)

export const PricingCreate = props => (
    <Create {...props}>
        <PricingForm />
    </Create>
)

export const PricingEdit = props => (
    <Edit {...props}>
        <PricingForm />
    </Edit>
)

