import React from "react";
import {
  Show,
  List,
  Datagrid,
  TextField,
  DateField,
  TabbedShowLayout,
  Tab,
  NumberField,
  ReferenceField,
  ShowController,
  ShowButton,
  EditButton,
  SimpleForm,
  SelectInput,
  Edit,
} from "react-admin";
import Chip from "@material-ui/core/Chip";
import DownloadXlsButton from "../components/campaigns/DownloadXlsButton";

const AddressOrderTitle = ({ record }) => {
  return <span>AddressOrder {record ? `${record.id}` : ""}</span>;
};

export const AddressOrderList = (props) => (
  <List {...props}>
    <Datagrid>
      <ReferenceField label="User" source="user.id" reference="users">
        <TextField source="username" label="User" />
      </ReferenceField>
      <TextField source="numberOfAddresses" />
      <NumberField
        source="order.HTPrice"
        label="Price (HT)"
        locales="fr-FR"
        options={{ style: "currency", currency: "EUR" }}
      />
      <TextField source="status" />
      <DateField source="order.date" label="Execute at" locales="fr-FR" />
      <ShowButton />
      <EditButton />
      <DownloadXlsButton />
    </Datagrid>
  </List>
);

const formatZone = (zone) => {
  const { nom_zone, lib_com, cp } = zone.properties;
  const additionnalInfos = [];
  if (cp && cp !== "0") {
    additionnalInfos.push(cp);
  }
  if (lib_com && lib_com !== nom_zone) {
    additionnalInfos.push(lib_com);
  }
  if (additionnalInfos.length === 0) {
    return nom_zone;
  }
  return `${nom_zone} (${additionnalInfos.join(" ")})`;
};

export const AddressOrderShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => {
      const record = controllerProps.record;

      return (
        <Show {...props} {...controllerProps} title={<AddressOrderTitle />}>
          <TabbedShowLayout>
            <Tab label="AddressOrder Info">
              <ReferenceField label="User" source="user.id" reference="users">
                <TextField source="username" label="User" />
              </ReferenceField>

              <TextField source="status" />
              {record && record.radius !== undefined && (
                <TextField source="address.full_address" label="Address" />
              )}
              {record && record.radius !== undefined && (
                <NumberField source="radius" />
              )}
              {record && record.radius === undefined && (
                <div>
                  {record.selected_zones.map((zone) => (
                    <Chip
                      key={formatZone(zone)}
                      label={formatZone(zone)}
                      style={{ margin: "4px" }}
                    />
                  ))}
                </div>
              )}
              <TextField source="status" />
              <TextField source="numberOfAddresses" />
              <NumberField
                source="order.HTPrice"
                label="Price (HT)"
                locales="fr-FR"
                options={{ style: "currency", currency: "EUR" }}
              />
              <DateField
                source="order.date"
                label="Execute at"
                locales="fr-FR"
              />
              <DateField
                source="updated_at"
                label="Ordered at"
                locales="fr-FR"
              />
              <DateField source="created_at" locales="fr-FR" />
            </Tab>
            <Tab label="Order">
              <NumberField source="numberOfAddresses" />
              <NumberField
                source="order.HTPrice"
                locales="fr-FR"
                options={{ style: "currency", currency: "EUR" }}
                label="Price (HT)"
              />
              <NumberField
                source="order.postagePrice"
                locales="fr-FR"
                options={{ style: "currency", currency: "EUR" }}
                label="Postage Price"
              />
              <NumberField
                source="order.taxes"
                locales="fr-FR"
                options={{ style: "currency", currency: "EUR" }}
                label="Taxes"
              />
              <NumberField
                source="order.totalPrice"
                locales="fr-FR"
                options={{ style: "currency", currency: "EUR" }}
                label="Total Price (TTC)"
              />
            </Tab>
          </TabbedShowLayout>
        </Show>
      );
    }}
  </ShowController>
);

export const AddressOrderEdit = (props) => {
  let choices = [
    { id: "payed", name: "payed" },
    { id: "waitingForHOConfirmation", name: "waitingForHOConfirmation" },
    { id: "confirmedByHO", name: "confirmedByHO" },
    { id: "deniedByHO", name: "deniedByHO" },
    { id: "ordered", name: "ordered" },
    { id: "requestForPrinting", name: "requestForPrinting" },
    { id: "received", name: "received" },
  ];

  return (
    <Edit {...props}>
      <SimpleForm {...props}>
        <SelectInput source="status" choices={[...choices]} />
      </SimpleForm>
    </Edit>
  );
};
